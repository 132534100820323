body {
  font-family: "Source Sans Pro", system-ui, sans-serif;
  margin: 0;
  overflow-x: hidden;
  background-color: white;
}

a {
  text-decoration: none;
}
